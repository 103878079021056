import React from "react";
import styles from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerLogo}>
        <a href="#home">STATELINE CLEANING SERVICES</a>
      </div>
      <nav className={styles.footerNav}>
        <ul>
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#services">Services</a>
          </li>
          <li>
            <a href="#about">About</a>
          </li>
          <li>
            <a href="#contact">Contact</a>
          </li>
        </ul>
      </nav>
      <div className={styles.footerBottom}>
        <p>&copy; {new Date().getFullYear()} Proudly built by Stateline Web Solutions, LLC</p>
      </div>
    </footer>
  );
};

export default Footer;
