import React, { useRef, useState, useEffect } from "react";
import styles from "./ContactSection.module.css";
import emailjs from "@emailjs/browser";

const ContactSection = () => {
  const form = useRef();
  const [success, setSuccess] = useState(null);
  const [errors, setErrors] = useState({}); // State for validation errors

  // Function to validate the form fields
  const validateForm = () => {
    const formData = new FormData(form.current);
    const errors = {};

    // Validate name
    if (!formData.get("name")) {
      errors.name = "Name is required";
    }

    // Validate email
    const email = formData.get("email");
    if (!email) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email address is invalid";
    }

    // You can add more validations here if needed

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm()) {
      emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, "contact_form", form.current, { publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY }).then(
        () => {
          setSuccess(true);
          form.current.reset();
        },
        (error) => {
          setSuccess(false);
          console.log("FAILED...", error.text);
        }
      );
    }
  };

  useEffect(() => {
    if (success !== null) {
      const timer = setTimeout(() => {
        setSuccess(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [success]);

  return (
    <div id="contact">
      <div className={styles.formPage}>
        <div className={styles.formSection}>
          <div className={styles["form-container"]}>
            <div className={styles.successMessage}>
              {success !== null && (
                <p
                  style={{
                    color: success ? "#4BB543" : "red",
                    fontWeight: "bold",
                    margin: 0
                  }}>
                  {success ? "Form submitted successfully!" : "Failed to submit the form. Please try again."}
                </p>
              )}
            </div>
            <h1>Contact Us</h1>
            <form ref={form} onSubmit={sendEmail}>
              <label htmlFor="name">Name:</label>
              <input type="text" id="name" name="name" />
              <div className={styles.errorMessage}>
                {errors.name && <p className={styles.error}>{errors.name}</p>} {/* Display name error */}
              </div>
              <label htmlFor="email">Email:</label>
              <input type="email" id="email" name="email" />
              <div className={styles.errorMessage}>
                {errors.email && <p className={styles.error}>{errors.email}</p>} {/* Display email error */}
              </div>
              <label htmlFor="phone">Phone:</label>
              <input type="tel" id="phone" name="phone" />
              <div className={styles.errorMessage}></div>
              <label htmlFor="subject">Subject:</label>
              <input type="text" id="subject" name="subject" />
              <div className={styles.errorMessage}></div>
              <label htmlFor="comments">Comments or Questions?:</label>
              <textarea id="comments" name="comments" rows="6"></textarea>
              <div className={styles.errorMessage}></div>
              <button type="submit" className={styles.submitButton}>
                Submit
              </button>
            </form>
          </div>
          <div className={styles["contact-info"]}>
            <h2>Contact Information</h2>
            <p>Email: contact@statelinecleaningservices.com</p>
            <p>Phone: (920) 381-5620</p>
            <h2>Hours of Operation</h2>
            <p>Monday-Friday: 5 PM - midnight</p>
            <p>Saturday: 6 AM - 10 PM</p>
            <p>Sunday: 2 PM - 8 PM</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactSection;
