import React from "react";
import Header from "./header/Header";
import LandingSection from "./landingSection/LandingSection";
import ServicesSection from "./servicesSection/ServicesSection";
import AboutSection from "./about/AboutSection";
import Footer from "./footer/Footer";
import ContactSection from "./contact/ContactSection";

function App() {
  return (
    <div className="App">
      <Header />
      <LandingSection />
      <ServicesSection />
      <AboutSection />
      <ContactSection />
      <Footer />
    </div>
  );
}

export default App;
