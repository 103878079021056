import React from "react";
import styles from "./AboutSection.module.css";
import aboutUs from "../assets/images/aboutUsImage.png";

const AboutSection = () => {
  return (
    <section id="about" className={styles.about}>
      <div className={styles.container}>
        <h2 className={styles.title}>About Us</h2>
        <div className={styles.content}>
          <p className={styles.overview}>
            At Stateline Cleaning Services, we are dedicated to providing top-notch commercial cleaning solutions. Founded with a passion for cleanliness and customer satisfaction, our mission is to deliver exceptional service while upholding the
            highest standards of professionalism and reliability.
          </p>
          <img src={aboutUs} alt="workers cleaning office" className={styles.aboutUsImage} />
          <div className={styles.USP}>
            <h3 className={styles.subtitle}>Why Choose Us?</h3>
            <ul className={styles.USPList}>
              <li className={styles.USPItem}>Experienced professionals with extensive industry knowledge.</li>
              <li className={styles.USPItem}>Eco-friendly cleaning solutions that are safe for your environment.</li>
              <li className={styles.USPItem}>Comprehensive services tailored to meet your unique needs.</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
