import React from "react";
import styles from "./ServicesSection.module.css";
import ecoClean from "../assets/images/EcoClean.PNG";
import { Navigation, Scrollbar, A11y, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const ServicesSection = () => {
  return (
    <div>
      <section id="services" className={styles.services}>
        <div className={styles.container}>
          <h2 className={styles.title}>Our Services</h2>
          <div className={styles.servicesList}>
            <div className={styles.serviceCategory}>
              <h3 className={styles.categoryTitle}>General Cleaning Services</h3>
              <ul className={styles.serviceList}>
                <li className={styles.serviceItem}>Daily Office Cleaning: Dusting, vacuuming, trash removal, and surface cleaning.</li>
                <li className={styles.serviceItem}>Floor Care: Sweeping, mopping, and polishing of various floor types (carpet, hardwood, tile).</li>
                <li className={styles.serviceItem}>Window Cleaning: Interior and exterior window washing and detailing.</li>
                <li className={styles.serviceItem}>Restroom Sanitation: Cleaning and sanitizing restrooms, replenishing supplies.</li>
                <li className={styles.serviceItem}>Kitchen and Breakroom Cleaning: Cleaning countertops, appliances, and sinks.</li>
                <li className={styles.serviceItem}>Trash and Recycling Management: Emptying bins and managing waste disposal.</li>
              </ul>
            </div>

            <div className={styles.serviceCategory}>
              <h3 className={styles.categoryTitle}>Additional Services</h3>
              <ul className={styles.serviceList}>
                <li className={styles.serviceItem}>Pressure Washing: Cleaning exterior surfaces with high-pressure water.</li>
                <li className={styles.serviceItem}>Janitorial Supplies: Provision of cleaning supplies and equipment.</li>
                <li className={styles.serviceItem}>Emergency Cleaning Services: Quick-response cleaning for urgent needs.</li>
                <li className={styles.serviceItem}>Event Cleaning: Setup and teardown cleaning for events, including post-event cleanups.</li>
              </ul>
            </div>
          </div>
        </div>
        <div>
          {" "}
          <div className={styles.testimonials}>
            <h3 className={styles.clientSubtitle}>What Our Clients Say</h3>
            <Swiper modules={[Navigation, Scrollbar, A11y, Autoplay]} spaceBetween={50} slidesPerView={1} autoplay={{ delay: 4000, disableOnInteraction: false }} loop={true} navigation={{ clickable: true }}>
              <SwiperSlide>
                <blockquote className={styles.testimonial}>
                  <p>"Stateline Cleaning Services transformed our office into a pristine environment. Their attention to detail is unmatched!"</p>
                  <footer>- Emily Smith, Office Manager</footer>
                </blockquote>
              </SwiperSlide>
              <SwiperSlide>
                <blockquote className={styles.testimonial}>
                  <p>"Their team is reliable, professional, and always delivers high-quality cleaning services. Highly recommended!"</p>
                  <footer>- John Davis, Business Owner</footer>
                </blockquote>
              </SwiperSlide>
              <SwiperSlide>
                <blockquote className={styles.testimonial}>
                  <p>"We use Stateline Cleaning Services, and they never disappoint. Exceptional service every time!"</p>
                  <footer>- Emily Collins, HR Manager</footer>
                </blockquote>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>

      <section className={styles.greenCleaning}>
        <div className={styles.container}>
          <img src={ecoClean} alt="Recycling bins in office" className={styles.ecoClean} />
          <h2 className={styles.greenTitle}>Our Commitment to Green Cleaning</h2>
          <p className={styles.greenText}>
            At Stateline Cleaning Services, we are proud to use green cleaning solutions that are not only effective but also environmentally friendly. Our green cleaning practices ensure that we minimize our impact on the environment while providing
            top-notch cleaning services. We use eco-friendly products and sustainable waste management techniques to create a healthier indoor environment for you.
          </p>
          <div className={styles.greenServicesList}>
            <h3 className={styles.greenSubtitle}>Green Cleaning Services</h3>
            <ul className={styles.serviceList}>
              <li className={styles.serviceItem}>Eco-Friendly Cleaning: Use of environmentally friendly cleaning products and methods.</li>
              <li className={styles.serviceItem}>Sustainable Waste Management: Implementing recycling programs and reducing waste.</li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ServicesSection;
