import React from "react";
import styles from "./LandingSection.module.css";
import landingImage from "../assets/images/OfficeSpace.png";
import logo from "../assets/images/SCSLogoOverlay.png";

const LandingSection = () => {
  const scrollToContact = () => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div id="home" className={styles.landing} style={{ backgroundImage: `url(${landingImage})` }}>
      <div className={styles.overlay}>
        <img src={logo} alt="Stateline Cleaning Services Logo" className={styles.logoImage} />
        <div className={styles.textAndButton}>
          <div className={styles.text}>
            <h1>What Can Stateline Cleaning Services Do For You?</h1>
            <p>
              At Stateline Cleaning Services, we pride ourselves on delivering exceptional commercial cleaning services tailored to your needs. Our team of experienced professionals ensures a spotless and hygienic environment, helping your business
              shine. Choose us for reliable, thorough, and eco-friendly cleaning solutions that make a difference.
            </p>
          </div>
          <div></div>
          <button className={styles.contactButton} onClick={scrollToContact}>
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};

export default LandingSection;
